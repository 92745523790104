import { LeftOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";

export default function PageTitle({ showBacBtn = false, text, center }: any) {
  const classes = useStyle();
  const navigate = useNavigate();

  return (
    <div
      className={classes.pageTitle}
      style={{ justifyContent: !!center ? "center" : "flex-start" }}
    >
      {showBacBtn && (
        <Button
          //   size="small"
          type="text"
          icon={<LeftOutlined />}
          onClick={() => navigate(-1)}
        />
      )}
      <Typography.Title level={4} style={{ paddingLeft: 10 }}>
        {text}
      </Typography.Title>
    </div>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  pageTitle: {
    width: "100%",
    display: "flex",
    marginBottom: 20,
    alignItems: "center",
    "& .ant-typography": {
      marginBottom: "0 !important",
    },
  },
}));
