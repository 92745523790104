import { Button, Col, Input, message, Row, Select, Typography } from "antd";
import Page from "components/Layout/Page";
import PageTitle from "components/Layout/PageTitle";
import { Field, Form, Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import { insertTest } from "utils/tests";

const defaultValues = {
  code: "",
  name: "",
  price: "",
  instruction: "",
};

export default function AddTest() {
  const classes = useStyle();
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState(defaultValues);

  const handleSubmit = async (
    values: typeof initialValues,
    { resetForm }: FormikHelpers<typeof initialValues>
  ) => {
    await insertTest(values).then((res) => {
      if (!!res?.id) {
        resetForm();
        message.success("Test added successfully!");
        navigate(-1);
      } else {
        message.error("Something went wrong!");
      }
    });
  };

  return (
    <Page>
      <PageTitle showBacBtn text="Add Test" />

      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <Row gutter={[20, 20]} className={classes.addTestForm}>
            <Col xs={24}>
              <Field
                as={Input}
                name="code"
                size="large"
                placeholder="Test Code"
              />
            </Col>
            <Col xs={24}>
              <Field
                as={Input}
                name="name"
                size="large"
                placeholder="Test Name"
              />
            </Col>
            <Col xs={24}>
              <Field
                as={Input}
                name="price"
                size="large"
                placeholder="Test Price (₹)"
              />
            </Col>
            <Col xs={24}>
              <Field
                as={Input}
                name="instruction"
                size="large"
                placeholder="Instructions"
              />
            </Col>
            <Col xs={24}>
              <Button block type="primary" size="large" htmlType="submit">
                Add
              </Button>
            </Col>
          </Row>
        </Form>
      </Formik>
    </Page>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  addTestForm: {
    marginTop: 30,
  },
}));
