const jssTheme = {
  colors: {
    primary: "#008DB9",
    secondary: "#F6FCFF",
    danger: "#ef5450",

    textPrimary: "#f7f7f7",
    textDark: "#505050",

    light100: "#fff",
    light200: "#fbfbff",
    light300: "#f7f7f7",
    light400: "#d8d8d8",
    light500: "#c4c4c4",

    dark400: "#7e7e7e",
    dark1000: "#000",
  },
};

export default jssTheme;
