import { Col, DatePicker, message, Modal, ModalProps, Row } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";
import { generateSlots } from "utils/agents";

interface AddSlotModalProps extends ModalProps {
  onSlotAdd: () => void;
}

export default function AddSlotModal({
  onSlotAdd,
  ...props
}: AddSlotModalProps) {
  const { agentId } = useParams();
  const classes = useStyle();
  const [date, setDate] = useState<any>(null);
  const [data, setData] = useState({
    start_time: null,
    end_time: null,
  });

  const handleTimeChange =
    (key: string) => (value: moment.Moment | null, timeString: string) => {
      setData((curr: any) => {
        //   @ts-ignore
        let dateArr: any = date.split("/");

        let newDate = `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}T${timeString}:00`;
        // curr[key] = moment(
        //   timeString + " " + date,
        //   "HH:mm DD/MM/YYYY"
        // ).format();
        curr[key] = newDate;

        return curr;
      });
    };

  const handleAdd = async () => {
    if (!!data.start_time && !!data.end_time) {
      await generateSlots({
        user_id: agentId,
        ...data,
      }).then((res) => {
        if (res.msg === "slots added") {
          message.success("Slot added successfully!");
          onSlotAdd();
        }
      });
    } else {
      message.info("Please enter the both times.");
    }
  };

  return (
    <Modal
      {...props}
      width="80vw"
      title="Add Slot"
      okText="Add"
      className={classes.addSlotModal}
      okButtonProps={{
        onClick: handleAdd,
      }}
    >
      <Row gutter={[15, 15]}>
        <Col span={24}>
          <DatePicker
            format={"DD/MM/YYYY"}
            picker="date"
            className={classes.timePicker}
            placeholder="Start Date"
            onChange={(val, str) => setDate(str)}
          />
        </Col>
        <Col span={24}>
          <DatePicker
            format={"HH:mm"}
            picker="time"
            disabled={!!!date}
            className={classes.timePicker}
            placeholder="Start Time"
            onChange={handleTimeChange("start_time")}
          />
        </Col>
        <Col span={24}>
          <DatePicker
            format={"HH:mm"}
            picker="time"
            disabled={!!!date}
            className={classes.timePicker}
            placeholder="End Time"
            onChange={handleTimeChange("end_time")}
          />
        </Col>
      </Row>
    </Modal>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  addSlotModal: {},
  timePicker: {
    width: "100%",
  },
}));
