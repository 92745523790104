import { EditOutlined } from "@ant-design/icons";
import { Card, Col, Row, Typography } from "antd";
import { createUseStyles } from "react-jss";
import {
  ClipboardListIcon,
  CogIcon,
  DocumentTextIcon,
  LogoutIcon,
  PencilAltIcon,
  SparklesIcon,
  UsersIcon,
} from "@heroicons/react/outline";
import { Link, useNavigate } from "react-router-dom";
import Page from "components/Layout/Page";
import { useLocalStorage } from "components/Auth/hooks/useLocalStorage";

export default function ExecutiveDashboard() {
  const classes = useStyle();
  const navigate = useNavigate();
  const [user, setUser] = useLocalStorage("user", { auth: false });

  const options = [
    {
      label: "Orders",
      icon: <ClipboardListIcon height={30} />,
      description: "Manage Orders",
      link: "/executive/orders",
    },
    {
      label: "Tests",
      icon: <PencilAltIcon height={30} />,
      description: "Manage Tests",
      link: "/executive/tests",
    },
    {
      label: "Agents",
      icon: <UsersIcon height={30} />,
      description: "Manage Agents",
      link: "/executive/agents",
    },
    // {
    //   label: "Statement",
    //   icon: <DocumentTextIcon height={30} />,
    //   description: "Account Statement",
    //   link: "/executive/account-statement",
    // },
    // {
    //   label: "Ratings",
    //   icon: <SparklesIcon height={30} />,
    //   description: "App/Agent Ratings",
    //   link: "/executive/ratings",
    // },
    // {
    //   label: "Settings",
    //   icon: <CogIcon height={30} />,
    //   description: "Manage Configs",
    //   link: "/executive/settings",
    // },
    {
      label: "Logout",
      icon: <LogoutIcon height={30} />,
      description: "Logout Account",
      onClick: () => {
        setUser({ auth: false });
        navigate("/login");
      },
    },
  ];

  return (
    <Page>
      <div className={classes.pageHeader}>
        <Typography.Title level={4}>Hey 👋, Welcome!</Typography.Title>
        <Typography.Text>What would you like to do?</Typography.Text>
      </div>

      <Row gutter={[20, 20]}>
        {options.map((opt, index) => (
          <Col key={index} xs={12}>
            <OptionCard data={opt} />
          </Col>
        ))}
      </Row>
    </Page>
  );
}

const OptionCard = ({ data }: any) => {
  const classes = useStyle();

  const CardContent = (
    <Card className={classes.optionCard} onClick={data?.onClick}>
      {data.icon}
      <Typography.Text strong className={classes.optionLabel}>
        {data.label}
      </Typography.Text>
      <Typography.Text
        type="secondary"
        className={classes.optionCardDescription}
      >
        {data.description}
      </Typography.Text>
    </Card>
  );

  if (!!data?.link) {
    return <Link to={data.link}>{CardContent}</Link>;
  }

  return CardContent;
};

const useStyle = createUseStyles(({ colors }: Theme) => ({
  pageHeader: {
    padding: [40, 0, 50],

    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  optionCard: {
    "& svg": {
      fontSize: 32,
      color: colors.dark400,
      marginBottom: 10,
    },

    "& .ant-card-body": {
      padding: [20, 5],
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    },
  },
  optionLabel: {
    color: colors.textDark,
  },
  optionCardDescription: {
    fontSize: 12,
  },
}));
