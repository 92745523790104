import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Card, Typography } from "antd";
import Page from "components/Layout/Page";
import PageTitle from "components/Layout/PageTitle";
import AddSlotModal from "components/Modals/AddSlotModal";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";
import { getSlots } from "utils/agents";

export default function AgentSlots({ showAdd = false }: any) {
  const classes = useStyle();
  const { agentId }: any = useParams();
  const [slots, setSlots] = useState<any[]>([]);
  const [addSlotModalVisible, setAddSlotModalVisible] = useState(false);

  const fetchAgentSlots = async () => {
    await getSlots(agentId).then((res: any[]) => {
      let data = [];
      let dates = res
        .map((i) => i.date)
        .sort(
          (a, b) =>
            moment(a, "DD/MM/YYYY").unix() - moment(b, "DD/MM/YYYY").unix()
        );

      // @ts-ignore
      dates = [...new Set(dates)];

      for (let i = 0; i < dates.length; i++) {
        const date = dates[i];
        data.push({
          date,
          slots: res
            .filter((i) => i.date === date)
            .sort(
              (a, b) =>
                moment(a.start_time).unix() - moment(b.start_time).unix()
            ),
        });
      }

      setSlots(data);
    });
  };

  useEffect(() => {
    fetchAgentSlots();
  }, [agentId]);

  return (
    <Page>
      <div style={{ display: "flex" }}>
        <PageTitle showBacBtn text="Slots" />
        <Button
          hidden={!showAdd}
          type="link"
          icon={<PlusCircleOutlined />}
          onClick={() => {
            setAddSlotModalVisible(true);
          }}
        >
          Add Slot
        </Button>
      </div>

      {slots.map((item) => (
        <div key={item.date}>
          <Typography.Text type="secondary" style={{ paddingLeft: 10 }}>
            {moment(item.date, "DD/MM/YYYY").format("MMM DD, YYYY")}
          </Typography.Text>

          <div className={classes.slotsWrapper}>
            {item.slots.map((slot: any, index: number) => (
              <Card
                key={index}
                className={classes.slotCard}
                style={{ background: !!slot?.order_id ? "#d8d8d8" : "#fff" }}
              >
                <Typography.Text>
                  {moment(slot.start_time).utcOffset(0, false).format("HH:mm")}{" "}
                  - {moment(slot.end_time).utcOffset(0, false).format("HH:mm")}
                </Typography.Text>
              </Card>
            ))}
          </div>
        </div>
      ))}

      <AddSlotModal
        visible={addSlotModalVisible}
        onCancel={() => {
          setAddSlotModalVisible(false);
        }}
        onSlotAdd={() => {
          fetchAgentSlots();
          setAddSlotModalVisible(false);
        }}
      />
    </Page>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  slotsWrapper: {
    paddingLeft: 10,
    margin: [15, 0, 50],
  },
  slotCard: {
    margin: 3,
    display: "inline-block",
    "& .ant-card-body": {
      padding: [5, 15],
    },
  },
}));
