import { LeftOutlined } from "@ant-design/icons";
import { Button, Col, Input, message, Row, Typography } from "antd";
import Page from "components/Layout/Page";
import PageTitle from "components/Layout/PageTitle";
import { Field, Form, Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import { insertAgent } from "utils/agents";

export default function AddAgent() {
  const classes = useStyle();
  const navigate = useNavigate();
  const [initialValues] = useState({
    mobile_number: "",
    name: "",
  });

  const handleSubmit = async (
    values: typeof initialValues,
    { resetForm }: FormikHelpers<typeof values>
  ) => {
    await insertAgent(values)
      .then((res) => {
        if (!!res?.id) {
          resetForm();
          message.success("Agent added succesfuly!");
          return navigate(-1);
        }

        message.error(res.error);
      })
      .catch((res) => {
        message.error(res.response.data.error);
      });
  };

  return (
    <Page>
      <PageTitle showBacBtn text="Add Agent" />

      <Formik onSubmit={handleSubmit} initialValues={initialValues}>
        <Form>
          <Row gutter={[20, 20]} className={classes.addAgentForm}>
            <Col xs={24}>
              <Field
                as={Input}
                size="large"
                name="name"
                placeholder="Agent Name"
              />
            </Col>
            <Col xs={24}>
              <Field
                as={Input}
                size="large"
                name="mobile_number"
                placeholder="Agent Mobile Number"
              />
            </Col>
            <Col xs={24}>
              <Button block type="primary" size="large" htmlType="submit">
                Add
              </Button>
            </Col>
          </Row>
        </Form>
      </Formik>
    </Page>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  addAgentForm: {
    marginTop: 30,
  },
}));
