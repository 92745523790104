import { message, Modal, ModalProps, Typography } from "antd";
import { useState } from "react";
import { createUseStyles } from "react-jss";
import OtpInput from "react-otp-input";
import { updateOrderStatus } from "utils/orders";

interface AgentsModalProps extends ModalProps {
  orderId: string;
  onConfirm: () => void;
}

export default function AgentMarkAsCollectedModal({
  onConfirm,
  orderId,
  ...props
}: AgentsModalProps) {
  const classes = useStyle();
  const [otpInput, setOtpInput] = useState("");

  const handleConfirm = async () => {
    // Do something...
    if (otpInput.length !== 6) return message.info("Please enter a valid OTP!");

    if (+orderId > 0) {
      await updateOrderStatus(orderId, "SAMPLE_COLLECTED").then((res) => {
        if (res?.msg === "updated") {
          setOtpInput("");
          message.success("Marked as collected successfully!");
          onConfirm();
        } else {
          message.error("Something went wrong!");
        }
      });
    }
  };

  return (
    <Modal
      {...props}
      width="80vw"
      title="Verify OTP"
      okText="Confirm"
      className={classes.markAsCompletedModal}
      okButtonProps={{
        onClick: handleConfirm,
      }}
    >
      <Typography.Text className={classes.otpHintText}>
        Enter the OTP sent to the customer, to Mark the sample as collected.
      </Typography.Text>
      <Typography.Text type="secondary" className={classes.otpHintTextDummy}>
        For testing purpose, enter any random 6 digits.
      </Typography.Text>

      <OtpInput
        isInputNum
        value={otpInput}
        onChange={setOtpInput}
        numInputs={6}
        containerStyle={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        inputStyle={{
          width: 35,
          height: 40,
          fontSize: 18,
          borderRadius: 5,
          border: "1px solid rgba(0,0,0,0.3)",
        }}
      />
    </Modal>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  markAsCompletedModal: {},
  otpHintText: {
    fontSize: 12,
    display: "block",
  },
  otpHintTextDummy: {
    fontSize: 10,
    display: "block",
    marginTop: 5,
    marginBottom: 15,
  },
}));
