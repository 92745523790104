import axios from "axios";
const url = "http://api.raseet.com/hspa";
const config: any = {
  method: "GET",
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};

export const sendOTP = async (mobileNumber: string) => {
  let endpoint = "/createLoginOtp";
  return await axios
    .post(
      url + endpoint,
      {
        mobile_number: mobileNumber,
        otp_type: "Login",
      },
      config
    )
    .then((res) => res.data);
};

export const validateOTP = async (mobileNumber: string, otp: string) => {
  let endpoint = `/validateOtp?otp=${otp}&otp_type=Login&mobile_number=${mobileNumber}`;
  return await axios.get(url + endpoint, config).then((res) => res.data);
};
