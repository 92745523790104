import { Link, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./hooks/useAuth";
import { useLocalStorage } from "./hooks/useLocalStorage";

export const ProtectedLayout = ({ userType }: any) => {
  const [user, setUser] = useLocalStorage("user", { auth: false });

  if (!user?.auth) {
    return <Navigate to="/login" />;
  } else if (user?.user_type !== userType) {
    return <Navigate to={`/${user.user_type.toLowerCase()}`} />;
  }
  return (
    <div>
      <Outlet />
    </div>
  );
};
