import { Button, Col, Input, message, Row, Select, Typography } from "antd";
import Page from "components/Layout/Page";
import PageTitle from "components/Layout/PageTitle";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useNavigate, useParams } from "react-router-dom";
import { getTests, insertTest } from "utils/tests";

const defaultValues = {
  code: "",
  name: "",
  price: "",
  instruction: "",
};

export default function EditTest() {
  const classes = useStyle();
  const navigate = useNavigate();
  let { testId } = useParams();

  const [initialValues, setInitialValues] = useState(defaultValues);
  const [loading, setLoading] = useState(true);

  const handleSubmit = async (
    values: typeof initialValues,
    { resetForm }: FormikHelpers<typeof initialValues>
  ) => {
    await insertTest(values).then((res) => {
      if (!!res?.id) {
        message.success("Test updated successfully!");
        navigate(-1);
      } else {
        message.error("Something went wrong!");
      }
    });
  };

  const fetchInitialData = async () => {
    await getTests("", testId).then((res) => {
      if (res?.length) {
        setInitialValues(res[0]);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    fetchInitialData();
    return () => {
      setLoading(true);
      setInitialValues(defaultValues);
    };
  }, [testId]);

  if (loading) {
    return null;
  }

  return (
    <Page>
      <PageTitle showBacBtn text="Edit Test" />

      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <Row gutter={[20, 20]} className={classes.editTestForm}>
            <Col xs={24}>
              <Field
                as={Input}
                name="code"
                size="large"
                placeholder="Test Code"
              />
            </Col>
            <Col xs={24}>
              <Field
                as={Input}
                name="name"
                size="large"
                placeholder="Test Name"
              />
            </Col>
            <Col xs={24}>
              <Field
                as={Input}
                name="price"
                size="large"
                type="number"
                placeholder="Test Price (₹)"
              />
            </Col>
            <Col xs={24}>
              <Field
                as={Input}
                name="instruction"
                size="large"
                placeholder="Instructions"
              />
            </Col>
            <Col xs={24}>
              <Button block type="primary" size="large" htmlType="submit">
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </Formik>
    </Page>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  editTestForm: {
    marginTop: 30,
  },
}));
