import axios from "axios";
const url = "http://api.raseet.com/hspa";
const config: any = {
  method: "GET",
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};

export const getOrders = async (id: string = "", agent_id: string = "") => {
  let endpoint = `/getorders?agent_id=${agent_id}&id=${id}`;
  return await axios.get(url + endpoint, config).then((res) => res.data);
};

export const getOrderStatuses = async () => {
  let endpoint = `/getorderstatus`;
  return await axios.get(url + endpoint, config).then((res) => res.data);
};

export const updateOrderStatus = async (
  order_id: string,
  order_status: string
) => {
  let endpoint = `/updateOrderStatus`;
  return await axios
    .post(
      url + endpoint,
      {
        order_id,
        order_status,
      },
      config
    )
    .then((res) => res.data);
};
