import axios from "axios";
const url = "http://api.raseet.com/hspa";
const config: any = {
  method: "GET",
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};

export const orderPayment = async (data: any) => {
  let endpoint = `/orderpayment`;
  return await axios.post(url + endpoint, data, config).then((res) => res.data);
};
