import { Avatar, Card, Modal, ModalProps, Typography } from "antd";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";

interface AgentsModalProps extends ModalProps {
  onAssign: () => void;
}

export default function AgentsModal({ onAssign, ...props }: AgentsModalProps) {
  const classes = useStyle();
  const [selectedAgent, setSelectedAgent] = useState<number | null>(null);

  const handleAgentClick = (index: number) => () => {
    setSelectedAgent(index);
  };

  return (
    <Modal
      {...props}
      width="80vw"
      title="Select Agent"
      okText="Assign"
      className={classes.agentsModal}
      okButtonProps={{
        onClick: onAssign,
      }}
    >
      {[1, 2, 3, 4, 5, 6].map((agent, index) => (
        <Card
          key={index}
          className={classes.agentCard}
          style={{ background: selectedAgent === index ? "#f7f7f7" : "#fff" }}
          onClick={handleAgentClick(index)}
        >
          <Avatar size={30} style={{ marginRight: 15 }} />
          <Typography.Text>M. Shinde</Typography.Text>
        </Card>
      ))}
    </Modal>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  agentsModal: {
    "& .ant-modal-body": {
      padding: 0,
      height: 400,
      overflowY: "auto",
    },
  },
  agentCard: {
    "& .ant-card-body": {
      padding: [20, 30],
    },
  },
}));
