import { LogoutOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Card, Col, message, Row, Typography } from "antd";
import { useLocalStorage } from "components/Auth/hooks/useLocalStorage";
import Page from "components/Layout/Page";
import AgentMarkAsCollectedModal from "components/Modals/AgentMarkAsCollectedModal";
import { useState } from "react";
import { useEffect } from "react";
import { createUseStyles } from "react-jss";
import { Link, useNavigate } from "react-router-dom";
import { getOrders } from "utils/orders";

const getAddressString = ({
  area_code,
  city,
  country,
  door,
  locality,
  name,
  state,
}: any) => {
  let str = "";

  let addressArr = [door, name, locality, city, state, country, area_code];

  addressArr.map((item, index) => {
    if (index) {
      str += `, ${item}`;
    } else {
      str += item;
    }
  });

  return str;
};

export default function AgentDashboard() {
  const classes = useStyle();
  const navigate = useNavigate();
  const [user, setUser] = useLocalStorage("user");

  const [tasks, setTasks] = useState<any[]>([]);
  const [selectedOrderId, setSelectedOrderId] = useState("0");
  const [markAsCompletedModalVisible, setMarkAsCompletedModalVisible] =
    useState(false);

  const logout = () => {
    setUser({ auth: false });
    navigate("/login");
  };

  const fetchTasks = async () => {
    await getOrders("", user?.user_id).then((res) => {
      if (res.length) {
        setTasks(res);
      }
    });
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  return (
    <Page>
      <div className={classes.pageHeader}>
        <Typography.Title level={4}>Hey, {user?.user_name} 👋</Typography.Title>
        <Typography.Text>Welcome to your dashboard!</Typography.Text>
      </div>

      <div>
        <Row gutter={[15, 15]} className={classes.tasksCardWrapper}>
          <Col span={24} className={classes.topBtns}>
            <Button
              type="link"
              icon={<SyncOutlined />}
              onClick={() => {
                message.loading("Refreshing...", 1);
                fetchTasks();
              }}
            >
              Refresh Tasks
            </Button>

            <Link to={`/agent/slots/${user?.user_id}`}>
              <Button type="link">View Slots</Button>
            </Link>
          </Col>

          {tasks.map((task, index) => (
            <Col key={index} xs={24}>
              <Card className={classes.tasksCard}>
                {task.items.map((item: any) => (
                  <div key={item.id}>
                    <Typography.Text strong>{item?.tests_name}</Typography.Text>
                    <br />
                  </div>
                ))}
                <Typography.Text>
                  Order Amount: ₹{task?.order_amount}
                </Typography.Text>
                <br />
                <Typography.Text>
                  Customer Name : {task?.customer_name}
                </Typography.Text>
                <br />
                <Typography.Text>
                  Customer Address :
                  <br />
                  {getAddressString(task?.billing_address?.address)}
                </Typography.Text>
                <br />
                <br />

                <Button
                  type="primary"
                  disabled={
                    !["PROVISIONAL", "CONFIRMED"].includes(task?.status)
                  }
                  onClick={() => {
                    setSelectedOrderId(task?.id);
                    setMarkAsCompletedModalVisible(true);
                  }}
                >
                  {["PROVISIONAL", "CONFIRMED"].includes(task?.status)
                    ? "MARK COLLECTED"
                    : "SAMPLE COLLECTED"}
                </Button>
              </Card>
            </Col>
          ))}
        </Row>
      </div>

      <Button
        type="text"
        icon={<LogoutOutlined />}
        onClick={logout}
        className={classes.logoutBtn}
      />

      <AgentMarkAsCollectedModal
        orderId={selectedOrderId}
        visible={markAsCompletedModalVisible}
        onCancel={() => {
          setSelectedOrderId("0");
          setMarkAsCompletedModalVisible(false);
        }}
        onConfirm={() => {
          fetchTasks();
          setSelectedOrderId("0");
          setMarkAsCompletedModalVisible(false);
        }}
      />
    </Page>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  tasksCardWrapper: {
    margin: [20, 0],
  },
  tasksCard: {
    "& .ant-card-body": {
      padding: 15,
    },
  },
  pageHeader: {
    padding: [10, 0],
    textAlign: "center",
  },
  logoutBtn: {
    position: "absolute",
    top: 10,
    right: 10,
    "& .anticon": {
      color: colors.light500,
      fontSize: 18,
    },
  },

  topBtns: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));
