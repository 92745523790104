import Login from "pages/Auth/Login";
import { createUseStyles } from "react-jss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "antd/dist/antd.less";
import ExecutiveDashboard from "pages/Executive/Dashboard";
import NotFound from "pages/Other/NotFound";
import Orders from "pages/Executive/Orders";
import Agents from "pages/Executive/Agents";
import AddAgent from "pages/Executive/AddAgent";
import Tests from "pages/Executive/Tests";
import AddTest from "pages/Executive/AddTest";
import EditTest from "pages/Executive/EditTest";
import AgentDashboard from "pages/Agent/Dashboard";
import { ProtectedLayout } from "components/Auth/ProtectedLayout";
import OrderPayment from "pages/Payment/OrderPayment";
import AgentSlots from "pages/Executive/AgentSlots";

export default function App() {
  const classes = useStyle();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFound />} />

        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Login />} />
        <Route path="/payment/order" element={<OrderPayment />} />

        <Route
          path="executive"
          element={<ProtectedLayout userType="Executive" />}
        >
          <Route index element={<ExecutiveDashboard />} />
          <Route path="orders" element={<Orders />} />

          <Route path="tests" element={<Tests />} />
          <Route path="tests/add" element={<AddTest />} />
          <Route path="tests/edit/:testId" element={<EditTest />} />

          <Route path="agents" element={<Agents />} />
          <Route path="agents/add" element={<AddAgent />} />
          <Route
            path="agents/slots/:agentId"
            element={<AgentSlots showAdd />}
          />
        </Route>

        <Route path="agent" element={<ProtectedLayout userType="Agent" />}>
          <Route index element={<AgentDashboard />} />
          <Route path="slots/:agentId" element={<AgentSlots />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({}));
