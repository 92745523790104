import { CogIcon, UserAddIcon } from "@heroicons/react/outline";
import { Avatar, Button, Card, Col, Row, Typography } from "antd";
import Page from "components/Layout/Page";
import PageTitle from "components/Layout/PageTitle";
import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";
import { getAgents } from "utils/agents";

export default function Agents() {
  const classes = useStyle();
  const [agents, setAgents] = useState<any[]>([]);

  const fetchAgents = async () => {
    await getAgents().then((res) => {
      if (res.length) {
        setAgents(res);
      }
    });
  };

  useEffect(() => {
    fetchAgents();
  }, []);

  return (
    <Page>
      <PageTitle showBacBtn text="Agents" />

      <Row className={classes.agentsCardWrapper} gutter={[10, 10]}>
        <Col xs={24}>
          <Link to="/executive/agents/add">
            <Button
              block
              size="large"
              icon={<UserAddIcon height={16} style={{ marginRight: 10 }} />}
              className={classes.addAgentBtn}
            >
              Add Agent
            </Button>
          </Link>
        </Col>
        {agents.map((agent, index) => (
          <Col xs={24}>
            <Link to={`/executive/agents/slots/${agent?.id}`}>
              <Card className={classes.agentsCard}>
                <Avatar size={30} />
                <Typography.Text strong>{agent.name}</Typography.Text>
                <Button type="text" icon={<CogIcon height={18} />} />
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </Page>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  agentsCardWrapper: {},
  agentsCard: {
    "& .ant-card-body": {
      padding: [15, 20],

      display: "flex",
      alignItems: "center",
    },
    "& .ant-avatar": {
      marginRight: 15,
    },
    "& .ant-btn": {
      marginLeft: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  addAgentBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
