import { Button, Col, Input, message, Result, Row, Typography } from "antd";
import Page from "components/Layout/Page";
import PageTitle from "components/Layout/PageTitle";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import { useLocation, useNavigate } from "react-router-dom";
import { orderPayment } from "utils/payments";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function OrderPayment() {
  const classes = useStyle();
  const query = useQuery();
  const navigate = useNavigate();

  const [paymentCaptured, setPaymentCaptured] = useState(false);
  const [initialValues] = useState({
    customer_upi_id: "",
  });

  const handleSubmit = async (
    values: typeof initialValues,
    { resetForm }: FormikHelpers<typeof initialValues>
  ) => {
    await orderPayment({
      ...values,
      transaction_id: query.get("transaction_id"),
      status: "PAID",
    }).then((res) => {
      if (!!res?.msg) {
        resetForm();
        setPaymentCaptured(true);
        message.success("Payment captured successfully!");
      } else {
        message.error("Something went wrong!");
      }
    });
  };

  const validate = (values: typeof initialValues) => {
    const errors: any = {};

    if (!values.customer_upi_id) {
      errors.customer_upi_id = "Please enter you UPI ID";
    } else if (
      !/^[a-zA-Z0-9.\\-_]{2,256}@[a-zA-Z]{2,64}$/i.test(values.customer_upi_id)
    ) {
      errors.customer_upi_id = "Please enter a valid UPI address";
    }

    return errors;
  };

  if (paymentCaptured) {
    return (
      <Page className={classes.orderPaymentPage}>
        <Result
          status="success"
          title={`Your payment of ₹${query.get("amt")} was successful!`}
          //   subTitle={`Transaction ID: ${query.get("transaction_id")}`}
          style={{ width: "100%" }}
          //   extra={[
          //     <Button type="primary" onClick={() => navigate(-1)}>
          //       Go Back
          //     </Button>,
          //   ]}
        />
      </Page>
    );
  }

  return (
    <Page className={classes.orderPaymentPage}>
      <PageTitle center text="Order Payment" />

      <div>
        <Typography.Text>
          Please enter your UPI ID to proceed with the payment of $
          {query.get("amt")} for your order.
        </Typography.Text>
        <Typography.Text type="secondary" className={classes.testText}>
          For testing purpose, use-{" "}
          <Typography.Text copyable>98765@test</Typography.Text>
        </Typography.Text>
      </div>

      <Formik
        validate={validate}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Row gutter={[15, 15]}>
              <Col xs={24}>
                <Field
                  size="large"
                  as={Input}
                  name="customer_upi_id"
                  placeholder="UPI ID"
                />
                <ErrorMessage
                  name="customer_upi_id"
                  component={Typography.Text}
                  className={classes.error}
                />
              </Col>

              <Col xs={24}>
                <Button
                  block
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={isSubmitting}
                >
                  Pay ₹{query.get("amt")}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Page>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  orderPaymentPage: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  testText: {
    fontSize: 12,
    display: "block",
    margin: [5, 0, 15],
  },
  error: {
    paddingLeft: 5,
    color: colors.danger,
    fontSize: 12,
  },
}));
