import {
  Badge,
  Button,
  Card,
  Col,
  message,
  Modal,
  Row,
  Typography,
} from "antd";
import Page from "components/Layout/Page";
import PageTitle from "components/Layout/PageTitle";
import AgentsModal from "components/Modals/AgentsModal";
import moment from "moment";
import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { getOrders, getOrderStatuses, updateOrderStatus } from "utils/orders";

const getAddressString = ({
  area_code,
  city,
  country,
  door,
  locality,
  name,
  state,
}: any) => {
  let str = "";

  let addressArr = [door, name, locality, city, state, country, area_code];

  addressArr.map((item, index) => {
    if (index) {
      str += `, ${item}`;
    } else {
      str += item;
    }
  });

  return str;
};

export default function Orders() {
  const classes = useStyle();
  const [agentsModalVisible, setAgentsModalVisible] = useState(false);
  const [orders, setOrders] = useState<any[]>([]);

  const handleStatusChange = (order: any) => async () => {
    Modal.confirm({
      title: `Update status for this order to ${order?.next_status}?`,
      onOk: async () => {
        await updateOrderStatus(order?.id, order?.next_status).then((res) => {
          if (res.msg === "updated") {
            message.success("Updated status successfully!");
            fetchOrders();
          }
        });
      },
    });
  };

  const fetchOrders = async () => {
    await getOrderStatuses().then(async (statusRes: any[]) => {
      await getOrders().then((ordersRes) => {
        if (ordersRes.length) {
          for (let i = 0; i < ordersRes.length; i++) {
            if (!!!ordersRes[i].status) {
              ordersRes[i].status = "PROVISIONAL";
              ordersRes[i].next_status = "CONFIRMED";
            } else if (ordersRes[i].status !== "REPORTS_GENRATED") {
              ordersRes[i].next_status = statusRes.find(
                (item) => item.previous_status === ordersRes[i].status
              )?.current_status;
            }
          }

          setOrders(ordersRes);
        }
      });
    });
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <Page>
      <PageTitle showBacBtn text="Orders" />

      <Row className={classes.ordersCardWrapper} gutter={[15, 15]}>
        {orders.map((order, index) => (
          <Col key={index} xs={24}>
            <Badge.Ribbon color="cyan" text={order?.status}>
              <Card>
                {order.items.map((item: any) => (
                  <div key={item.id}>
                    <Typography.Text strong>{item?.tests_name}</Typography.Text>
                    <br />
                  </div>
                ))}
                <Typography.Text>
                  Order Amount: ₹{order?.order_amount}
                </Typography.Text>
                <br />
                <Typography.Text>
                  Customer Name : {order?.customer_name}
                </Typography.Text>
                <br />
                <Typography.Text>
                  Customer Address :
                  <br />
                  {getAddressString(order?.billing_address?.address)}
                </Typography.Text>

                <Row gutter={[15, 15]} className={classes.orderActionBtns}>
                  {/* <Col xs={24}>
                    <Button
                      block
                      type="primary"
                      hidden={
                        !!order?.agent_id ||
                        order?.status === "SAMPLE_COLLECTED"
                      }
                      onClick={() => setAgentsModalVisible(true)}
                    >
                      Assign
                    </Button>
                  </Col> */}

                  <Col hidden={order?.status === "REPORTS_GENRATED"} xs={24}>
                    <Button block onClick={handleStatusChange(order)}>
                      {order?.next_status}
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Badge.Ribbon>
          </Col>
        ))}
      </Row>

      <AgentsModal
        visible={agentsModalVisible}
        onCancel={() => {
          setAgentsModalVisible(false);
        }}
        onAssign={() => {
          setAgentsModalVisible(false);
          message.success("Assigned successfully!");
        }}
      />
    </Page>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  ordersCardWrapper: {},
  orderActionBtns: {
    marginTop: 15,
  },
}));
