import axios from "axios";
const url = "http://api.raseet.com/hspa";
const config: any = {
  method: "GET",
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};

export const getAgents = async () => {
  let endpoint = `/getagents`;
  return await axios.get(url + endpoint, config).then((res) => res.data);
};

export const insertAgent = async (data: any) => {
  let endpoint = `/insertAgent`;
  return await axios.post(url + endpoint, data, config).then((res) => res.data);
};

export const getSlots = async (agentId: string) => {
  let endpoint = `/getslots?agent_id=${agentId}`;
  return await axios.get(url + endpoint, config).then((res) => res.data);
};

export const generateSlots = async (data: any) => {
  let endpoint = `/genrateslots`;
  return await axios.post(url + endpoint, data, config).then((res) => res.data);
};
