import { LeftOutlined } from "@ant-design/icons";
import {
  PencilAltIcon,
  UserAddIcon,
  ViewGridAddIcon,
} from "@heroicons/react/outline";
import { Avatar, Button, Card, Col, Row, Typography } from "antd";
import Page from "components/Layout/Page";
import PageTitle from "components/Layout/PageTitle";
import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";
import { getTests } from "utils/tests";

export default function Tests() {
  const classes = useStyle();
  const [tests, setTests] = useState<any[]>([]);

  const fetchTest = async () => {
    await getTests().then((res) => {
      if (res.length) {
        setTests(res);
      }
    });
  };

  useEffect(() => {
    fetchTest();
  }, []);

  return (
    <Page>
      <PageTitle showBacBtn text="Tests" />

      <Row className={classes.agentsCardWrapper} gutter={[10, 10]}>
        <Col xs={24}>
          <Link to="/executive/tests/add">
            <Button
              block
              size="large"
              icon={<ViewGridAddIcon height={16} style={{ marginRight: 10 }} />}
              className={classes.addAgentBtn}
            >
              Add Test
            </Button>
          </Link>
        </Col>

        {tests.map((test, index) => (
          <Col xs={24}>
            <Card className={classes.agentsCard}>
              <Typography.Text strong>{test.name}</Typography.Text>

              <Link to={`/executive/tests/edit/${test.id}`}>
                <Button type="text" icon={<PencilAltIcon height={18} />} />
              </Link>
            </Card>
          </Col>
        ))}
      </Row>
    </Page>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  agentsCardWrapper: {},
  agentsCard: {
    "& .ant-card-body": {
      padding: [15, 20],

      display: "flex",
      alignItems: "center",
    },
    "& .ant-avatar": {
      marginRight: 15,
    },
    "& .ant-btn": {
      marginLeft: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  addAgentBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
