import { Button, Col, Input, Layout, message, Row, Typography } from "antd";
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import AppLogo from "assets/images/app-logo.jpg";
import { Navigate, useNavigate } from "react-router-dom";
import { sendOTP, validateOTP } from "utils/auth";
import { useAuth } from "components/Auth/hooks/useAuth";
import { useLocalStorage } from "components/Auth/hooks/useLocalStorage";
import OtpInput from "react-otp-input";

export default function Login() {
  const classes = useStyle();
  const navigate = useNavigate();
  const [user, setUser] = useLocalStorage("user", { auth: false });

  const [otpSent, setOtpSent] = useState(false);
  const [mobileInput, setMobileInput] = useState("");
  const [otpInput, setOtpInput] = useState("");

  const handleMobileInputChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => setMobileInput(event.target.value);

  const handleOtpInputChange = (value: any) => setOtpInput(value);

  const handleSubmitClick = async () => {
    if (!otpSent) {
      // Check mobile number input length
      if (mobileInput.length !== 10)
        return message.info("Please enter a valid mobile number.");

      await sendOTP(mobileInput).then((res) => {
        if (res.status === "success") {
          message.success(res.message);
          setOtpSent(true);
        } else {
          message.error(res.error);
        }
      });
    } else {
      if (otpInput.length !== 6)
        return message.info("Please enter a valid mobile number.");

      await validateOTP(mobileInput, otpInput)
        .then((res) => {
          if (res.auth) {
            message.success(res.message);
            // localStorage.setItem("user", JSON.stringify(res.data));
            setUser(res.data);
          }
        })
        .catch((res) => {
          console.log(res);
          message.error(res.response.data.error);
        });
    }
  };

  if (!!user && user?.auth) {
    return <Navigate to={`/${user.user_type.toLowerCase()}`} />;
  }

  return (
    <div className={classes.loginPage}>
      <Row justify="center" align="middle" gutter={[20, 20]}>
        <Col xs={"auto"} className={classes.appLogo}>
          {/* <img src={AppLogo} alt="" /> */}
        </Col>

        <Col xs={24}>
          <Typography.Title style={{ textAlign: "center" }}>
            Login
          </Typography.Title>
        </Col>

        <Col hidden={otpSent} xs={24}>
          <Typography.Text className={classes.otpInputHint}>
            An OTP will be sent to this registered mobile number.
          </Typography.Text>
          <Typography.Text
            type="secondary"
            className={classes.mobileHintTextDummy}
          >
            For testing purpose:
            <br />
            ADMIN : <Typography.Text copyable>9518760250</Typography.Text>
            <br />
            AGENT : <Typography.Text copyable>7042046838</Typography.Text>
          </Typography.Text>

          <Input
            prefix="+91"
            size="large"
            placeholder="xxxxx xxxxx"
            value={mobileInput}
            onChange={handleMobileInputChange}
          />
        </Col>

        <Col hidden={!otpSent} xs={24}>
          <Typography.Text className={classes.otpInputHint}>
            Please enter the OTP sent to your registered mobile number.
          </Typography.Text>
          <Typography.Text
            type="secondary"
            className={classes.otpHintTextDummy}
          >
            For testing purpose, enter any random 6 digits.
          </Typography.Text>

          <div style={{ padding: 5 }}>
            <OtpInput
              isInputNum
              value={otpInput}
              onChange={handleOtpInputChange}
              numInputs={6}
              containerStyle={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              inputStyle={{
                width: 35,
                height: 40,
                fontSize: 18,
                borderRadius: 5,
                border: "1px solid rgba(0,0,0,0.3)",
              }}
            />
          </div>
        </Col>

        <Col xs={24}>
          {otpSent ? (
            <Button
              block
              size="large"
              type="primary"
              onClick={handleSubmitClick}
            >
              Verify OTP
            </Button>
          ) : (
            <Button
              block
              size="large"
              type="primary"
              onClick={handleSubmitClick}
            >
              Send OTP
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
}

const useStyle = createUseStyles(({ colors }: Theme) => ({
  loginPage: {
    minHeight: "100vh",
    padding: 50,
    display: "flex",
    alignItems: "center",
  },
  appLogo: {
    width: "100%",
    marginBottom: 40,

    position: "fixed",
    top: 20,
    left: 0,
    display: "flex",
    justifyContent: "center",

    "& img": {
      width: 120,
      objectFit: "contain",
    },
  },
  mobileInputHint: {
    fontSize: 12,
    marginBottom: 10,
    display: "block",
  },
  otpInputHint: {
    fontSize: 12,
    display: "block",
  },
  otpHintTextDummy: {
    fontSize: 10,
    display: "block",
    marginTop: 5,
    marginBottom: 15,
  },
  mobileHintTextDummy: {
    fontSize: 12,
    display: "block",
    marginTop: 5,
    marginBottom: 15,
  },
}));
